/// <reference types="@wcp/wcp-consent" />

import { createContext, type FC, useContext, useEffect, useState } from 'react';

import { setCookie, setGranularConsentCookie } from '../util/cookiesConsent.js';
import { COOKIE_BANNER_ID } from './WCPCookieBanner.js';

interface CookieConsent {
    advertising: boolean;
    analytics: boolean;
    socialMedia: boolean;
}

const WCPCookieContext = createContext<{
    siteConsent: WcpConsent.SiteConsent | undefined;
    consentDetails: CookieConsent;
    manageConsent?: () => void;
}>({
    siteConsent: undefined,
    consentDetails: {
        advertising: false,
        analytics: false,
        socialMedia: false,
    },
});

interface CookieContextProviderProps {
    locale: string;
    children: React.ReactNode;
}

let siteConsent: WcpConsent.SiteConsent;

export const CookieContextProvider: FC<CookieContextProviderProps> = ({ children, locale }) => {
    const [consentDetails, setConsentDetails] = useState<CookieConsent>({
        advertising: false,
        analytics: false,
        socialMedia: false,
    });

    const onConsentChanged = () => {
        const newConsent = siteConsent.getConsent();
        // no non essential analytics or social media cookies to manage
        // non essential advertising cookies only apply to braze, handled by sharing consent via provider
        if (newConsent.Analytics === true) {
            turnOnAnalyticsCookies();
        } else {
            removeAnalyticsCookies();
        }
        setGranularConsentCookie({
            analytics: newConsent.Analytics,
            advertising: newConsent.Advertising,
            social: newConsent.SocialMedia,
        });
        setConsentDetails({
            advertising: newConsent.Advertising,
            analytics: newConsent.Analytics,
            socialMedia: newConsent.SocialMedia,
        });
    };

    useEffect(() => {
        WcpConsent.init(
            locale,
            COOKIE_BANNER_ID,
            (err, _siteConsent) => {
                siteConsent = _siteConsent!;
            },
            onConsentChanged
        );

        // remove deprecated non essential cookies
        removeGACookies();

        const initialConsent = siteConsent.getConsent();
        setConsentDetails({
            advertising: initialConsent.Advertising,
            analytics: initialConsent.Analytics,
            socialMedia: initialConsent.SocialMedia,
        });
    }, [locale]);

    const manageConsent = () => {
        siteConsent.isConsentRequired && siteConsent?.manageConsent();
    };

    const turnOnAnalyticsCookies = () => {
        setCookie(true);
    };

    const removeAnalyticsCookies = () => {
        if (typeof document !== 'undefined') {
            removeGACookies();
            setCookie(false);
        }
    };

    const removeGACookies = () => {
        if (typeof document !== 'undefined') {
            // remove all cookies that start with _g
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].split('=');
                const name = cookie[0].trim();
                if (name.startsWith('_g')) {
                    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                }
            }
            window.dataLayer = undefined;
        }
    };

    return (
        <WCPCookieContext.Provider
            value={{
                siteConsent,
                consentDetails,
                manageConsent,
            }}
        >
            {children}
        </WCPCookieContext.Provider>
    );
};

export const useWCPConsent = () => {
    return useContext(WCPCookieContext);
};

export const getUserConsent = () => {
    return siteConsent && siteConsent.getConsent();
};
