import * as oneDS from '@microsoft/1ds-analytics-web-js';
import { type FC, type PropsWithChildren, useEffect } from 'react';

import { getUserConsent, useWCPConsent } from './useCookies.js';
import { isWCPCookieBannerEnabled } from './WCPCookieBanner.js';

interface PropertyConfiguration {
    env: string;
    callback?: {
        userConsentDetails: () => Record<WcpConsent.consentCategories, boolean>;
    };
}

const isAnalyticsEnabled = (): boolean => {
    let isEnabledInBrowser = false;
    try {
        isEnabledInBrowser = typeof window !== 'undefined';
    } catch (error) {
        console.error('Error accessing localStorage:', error);
    }

    return isEnabledInBrowser;
};

// Global variable to hold the analytics instance
let analytics: oneDS.ApplicationInsights | undefined = undefined;
let hasInitialized = false;

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
    const isEnabled = isAnalyticsEnabled();
    const { siteConsent } = useWCPConsent();

    useEffect(() => {
        if (siteConsent && isEnabled && !analytics && !hasInitialized) {
            const wcpCookiesEnabled = isWCPCookieBannerEnabled();
            const analyticsInstance = new oneDS.ApplicationInsights();
            const config = {
                instrumentationKey: Environment.ADOBE_ANALYTICS_INSTRUMENTATION_KEY,
                propertyConfiguration: {
                    env: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
                    // default to false until we have a way to determine if the user has opted in
                    gpcDataSharingOptIn: false,
                } as PropertyConfiguration,
                webAnalyticsConfiguration: {
                    autoCapture: {
                        scroll: false,
                        pageView: true,
                        onLoad: true,
                        onUnload: false,
                        click: true,
                        resize: false,
                        jsError: true,
                    },
                },
            };

            if (wcpCookiesEnabled) {
                config.propertyConfiguration.callback = {
                    userConsentDetails: () => {
                        const consent = getUserConsent();
                        // If consent is not available, return default consent details
                        return (
                            consent ?? {
                                Required: true,
                                Analytics: false,
                                Advertising: false,
                                SocialMedia: false,
                            }
                        );
                    },
                };
            }

            analyticsInstance.initialize(config, []);
            analytics = analyticsInstance;
            hasInitialized = true;
        }
    }, [isEnabled]);

    return <>{children}</>;
};

export const logPageView = () => {
    analytics && analytics.capturePageView();
};
